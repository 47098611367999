<template>
    <div class="page">
        <div class="container">
            <div style="text-align: center;font-size: 36px;">{{ active === 1 ? '身份验证' : active === 2 ? '设置密码' : '找回成功' }}</div>
            <el-steps :active="active"  style="margin-top: 20px;margin-left: 20px;">
                <el-step title="身份验证"></el-step>
                <el-step title="设置密码"></el-step>
                <el-step title="找回成功"></el-step>
            </el-steps>
            <!-- 身份验证 -->
            <div v-if="active === 1">
                <div>
                    <div style="display: flex; margin-top: 120px; justify-content: center;">
                        <div style="margin-top: 10px;width: 100px; text-align: right;">注册邮箱:</div>
                        <div style="margin-left: 20px;">
                            <el-input v-model="email" placeholder="请输入邮箱" style="width: 400px;"></el-input>
                        </div>
                    </div>
                    <div style="display: flex; margin-top: 20px; justify-content: center;">
                        <div style="margin-top: 10px;width: 100px; text-align: right;">验证码:</div>
                        <div style="margin-left: 20px;">
                            <el-input v-model="yzm" placeholder="请输入验证码" style="width: 200px;"></el-input>
                        </div>
                        <div style="width: 200px;">
                            <div style="margin-top: 20px; margin-left: 10px; font-size: 12px;color: #0aa1eb; cursor: pointer;" @click="sendma">
                                <span v-if="isSend">没有收到? <span style="color: #fff;">{{ time }}</span> 秒后重试</span>
                                <span v-else>
                                    点击获取验证码
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin-top: 150px; ">
                    <el-button type="primary" size="medium" style="width: 400px;" @click="next">下一步</el-button>
                </div>
            </div>
            <!-- 密码找回 -->
            <div v-if="active === 2">
                <div>
                    <div style="display: flex; margin-top: 120px; justify-content: center;">
                        <div style="margin-top: 10px;width: 100px; text-align: right;">密码:</div>
                        <div style="margin-left: 20px;">
                            <el-input v-model="password" placeholder="请输入新密码" style="width: 400px;" show-password></el-input>
                        </div>
                    </div>
                    <div style="display: flex; margin-top: 20px; justify-content: center;">
                        <div style="margin-top: 10px;width: 100px; text-align: right;">确认密码:</div>
                        <div style="margin-left: 20px;">
                            <el-input v-model="newpassword" placeholder="请再次输入密码" style="width: 400px;" show-password></el-input>
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin-top: 100px; ">
                    <el-button type="primary" size="medium" style="width: 400px;" @click="submit">确认</el-button>
                </div>
            </div>

            <!-- 找回成功 -->
            <div v-if="active === 3">
                <div style="color: #0aa1eb; font-size: 300px; text-align: center; margin-top: 40px;">
                    <i class="el-icon-circle-check"></i>
                </div>
                <div style="text-align: center; font-size: 36px;">修改成功</div>
                <div style="text-align: center; margin-top: 50px; ">
                    <el-button type="primary" size="medium" style="width: 400px;" @click="goLogin">返回登录</el-button>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { $user } from "@/services";
  export default {
    name: "Login",
    data () {
      return {
        password: "",
        newpassword:'',
        active:1,
        isSend:false,
        time: 60,
        timer:null,
        email:'',
        yzm:'',
        emailYzm:''
      };
    },
    methods: {
        async sendma() {
            if (!this.isSend) {
                this.isSend = true
                let res = await $user.resetPasswordYzm(this.email)
                if (res.code === '0') {
                    this.emailYzm = res.data
                    this.timer = setInterval(() => {
                        if (this.time > 0) {
                            this.time --
                        } else {
                            clearInterval(this.timer)
                            this.time = 60
                            this.isSend = false
                        }
                    },1000)
                } else {
                    this.$message.error('系统错误,请联系管理员')
                }
            }
        },
        next() {
            if (this.yzm === '') {
                this.$message.error('请输入验证码')
                return
            }
            if (this.emailYzm === '') {
                this.$message.error('请点击获取验证码')
                return
            }
            if (this.emailYzm !== this.yzm) {
                this.$message.error('您输入的验证码不匹配,请重试')
                return
            }
            this.active = 2
        },
        async submit() {
            if (this.password === '') {
                this.$message.error('请输入密码')
                return
            }
            if (this.newpassword === '') {
                this.$message.error('请确认密码')
                return
            }
            if (this.newpassword !== this.password) {
                this.$message.error('两次密码不一致,请重新输入')
                return
            }
            let params = {}
            params.email = this.email
            params.password = this.password 
            let res = await $user.resetPassword(params)
            if (res.code == 0) {
                this.active = 3
            }
        },
        goLogin() {
            this.$router.push('/login')
        }
    },
  };
  </script>
  <style lang="less" scoped>
  @cyan: #00baff;
  .page {
    background-image: url(../../assets/bj-1.jpg);
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    height: 100vh;
    padding-top: 140px;
  }
  .container {
    width: 700px; height: 700px;
    background: rgba(0, 0, 0, .3);
    margin: 0 auto;
    border-radius: 10px;
    padding: 20px;
    color: #fff;
  }
  </style>